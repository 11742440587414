.container {
  width: 45rem;
}

.content {
  @apply flex flex-col h-full;
  margin: 1.875rem 8.5rem 5rem 8.5rem;
}

.title {
  @apply font-gilda text-center mb-6 text-primaryA;
  font-size: 2.125rem;
  line-height: 3rem;
}

.messageInfo {
  @apply font-inter text-sm text-primaryA mx-auto;
}

.messageVpPeriod {
  @apply font-inter text-sm text-primaryA mx-auto;
}

.suggestionMessage {
  @apply font-inter text-sm text-primaryA mx-auto;
  margin-top: 1rem;
  text-align: center;
}

.buttonsContainer {
  @apply flex flex-col py-4;
}

.button {
  @apply w-full mt-3;
}

.notInterested {
  @apply text-sm mx-auto mt-6 cursor-pointer ease-linear duration-200;
}
.notInterested:hover {
  @apply text-gray-600;
}

@media (max-width: 767px) {
  .modalWrapper {
    @apply h-auto w-96;
  }

  .container {
    @apply w-full flex justify-center;
  }

  .content {
    margin: 0 3.25rem 0 3.25rem;
  }

  .title {
    @apply mb-2;
  }

  .messageInfo {
    @apply font-inter text-sm text-primaryA;
  }

  .buttonsContainer {
    @apply py-2;
  }
}
